<template>
  <div class="roleBackground">
    <BottomRole v-if="refresh" :roleId="$route.params.id"></BottomRole>
  </div>
</template>
<script>
import BottomRole from "@/components/home/BottomRole.vue";

export default {
  components: { BottomRole },
  data() {
    return {
      refresh: true,
    };
  },
  watch: {
    $route(to) {
      if (to.name !== "characterDetail") return;
      this.refresh = false;
      this.$nextTick(() => {
        this.refresh = true;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.roleBackground {
  position: relative;
  background-image: url("/static/img/home/bottom/roleBackground.jpg");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  padding: 20px 20px 40px 0;
}
@media only screen and(max-width: 1600px) {
  .roleBackground {
    padding: 20px 0 40px;
  }
}
</style>
